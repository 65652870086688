import React, { useState } from "react"
import { graphql } from "gatsby"
import { MaterialStartupHero } from "components/pages/solutions/material-startup/hero"
import MaterialStartupDetails from "components/pages/solutions/material-startup/details"
import MaterialStartupBenefits from "components/pages/solutions/material-startup/benefits"
import MaterialStartupFeatures from "components/pages/solutions/material-startup/features";
import { RegisterForm } from "components/pages/solutions/material-startup/register-form/register-form";
import { MaterialStartupRegisterCard } from "components/pages/solutions/material-startup/register-card";

const MaterialStartup = () => {
  const [openForm, setOpenForm] = useState(
    location.search === "?fromCampaignMail" ? true : false,
  );
  return (
    <main className="-mt-32 sm:-mt-50">
      <MaterialStartupHero setOpenForm={setOpenForm} />
      <div className="flex justify-center mt-[-100px] sm:mt-[-150px] lg:mt-[-200px]">
        <MaterialStartupBenefits setOpenForm={setOpenForm} />
      </div>
      <div className="lg:pb-24">
        <MaterialStartupDetails setOpenForm={setOpenForm} />
      </div>

      <MaterialStartupFeatures />
      <div className="bg-[#1B088C] flex flex-col gap-16 justify-end h-[700px] -mt-[250px] sm:h-[600px] sm:-mt-[250px] md:-mt-[325px] lg:-mt-[250px] z-0">
        <MaterialStartupRegisterCard setOpenForm={setOpenForm} />
      </div>
      <RegisterForm openForm={openForm} setOpenForm={setOpenForm} />
    </main>
  );
};

export default MaterialStartup

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
