import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Input, Select } from "components/library";
import * as EmailValidator from "email-validator";
import { phone } from "phone";
import countryData from "phone/dist/data/country_phone_data";

// const LANGUAGES = ["English", "Japanese", "Spanish", "Portuguese"]

const LANGUAGES_COUNT = 4;

const API_URL_EMAIL_CHECAK = `${process.env.GATSBY_BACKEND_API_V1}/_material_startup_registration/check_email`;
const API_URL_PHONE_CHECAK = `${process.env.GATSBY_BACKEND_API_V1}/_material_startup_registration/check_phone`;

export default function RegisterFormSecondStep({
  registerFormData,
  setRegisterFormData,
  registerFormErrorData,
  setRegisterFormErrorData,
  setEmailPhoneDuplicate,
  emailPhoneDuplicate,
}: {
  registerFormData: any;
  setRegisterFormData: Function;
  registerFormErrorData: any;
  setRegisterFormErrorData: Function;
  setEmailPhoneDuplicate: Function;
  emailPhoneDuplicate: any;
}) {
  const { t } = useTranslation();

  const handleDataChange = async (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;

    // if (name === "phoneNumber") {
    //   setPhoneError("");
    //   const phone_number = phone(value, {
    //     country: registerFormData.country,
    //   }).phoneNumber;

    //   if (phone(value, { country: registerFormData.country }).isValid) {
    //     const body = JSON.stringify({
    //       phone_number,
    //     });

    //     try {
    //       const response = await fetch(API_URL_PHONE_CHECAK, {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body,
    //       });
    //       const responseJson = await response.json();
    //       if (responseJson.result.status === "Success") {
    //         setPhoneError(responseJson.result.message);
    //         setEmailPhoneDuplicate((state: any) => ({ ...state, phone: true }));
    //       } else {
    //         setEmailPhoneDuplicate((state: any) => ({
    //           ...state,
    //           phone: false,
    //         }));
    //       }
    //     } catch (err) {
    //       setPhoneError("Error in checking, type again");
    //     }
    //   } else if (
    //     registerFormData.phoneNumber === "" ||
    //     !phone(registerFormData.phoneNumber, {
    //       country: registerFormData.country,
    //     }).isValid ||
    //     emailPhoneDuplicate.phone
    //   ) {
    //     setPhoneError("Enter valid phone number");
    //   }
    // }

    // if (name === "businessEmail") {
    //   setEmailError("");
    //   if (EmailValidator.validate(value)) {
    //     const body = JSON.stringify({
    //       business_email: value,
    //     });

    //     try {
    //       const response = await fetch(API_URL_EMAIL_CHECAK, {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body,
    //       });
    //       const responseJson = await response.json();
    //       if (responseJson.result.status === "Success") {
    //         setEmailError(responseJson.result.message);
    //         setEmailPhoneDuplicate((state: any) => ({ ...state, email: true }));
    //       } else {
    //         setEmailPhoneDuplicate((state: any) => ({
    //           ...state,
    //           email: false,
    //         }));
    //       }
    //     } catch (err) {
    //       setEmailError("Error in checking, type again");
    //     }
    //   } else if (
    //     registerFormData.businessEmail === "" ||
    //     !EmailValidator.validate(registerFormData.businessEmail) ||
    //     emailPhoneDuplicate.email
    //   ) {
    //     setEmailError("Enter valid email");
    //   }
    // }

    setRegisterFormErrorData((prevState: any) => ({
      ...prevState,
      [name]: false,
    }));
    setRegisterFormData({
      ...registerFormData,
      [name]: value,
    });
  };

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  return (
    <div className="flex flex-col gap-4 sm:gap-8 text-black">
      {/* title */}
      <div className="self-start font-medium text-black text-xl md:text-2xl my-2 sm:my-4">
        {t("material-startup-page.register-form.contact-information.heading")}
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <div className={`flex flex-col gap-2 items-start basis-1/2`}>
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.contact-information.phoneNumber",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <div className="flex gap-2 w-full">
            <Select
              name="country"
              className={`h-12 text-base placeholder:text-base placeholder:text-gray-500 
              border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)] basis-1/2
              ${registerFormData.country ? "" : "text-gray-400"}`}
              value={registerFormData.country}
              onChange={handleDataChange}
              required
              isError={registerFormErrorData.country}
            >
              {countryData.map((country, key) => {
                return (
                  <option key={key} value={country.alpha2}>
                    {country.country_name}
                  </option>
                );
              })}
            </Select>
            <Input
              name="phoneNumber"
              type="tel"
              value={registerFormData.phoneNumber}
              onChange={handleDataChange}
              className={`bg-white h-12 text-base placeholder:text-base placeholder:text-gray-500 border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)] `}
              placeholder={`${t(
                "material-startup-page.register-form.contact-information.phoneNumberPlaceholder",
              )}`}
              required
              isError={registerFormErrorData.phoneNumber}
              onBlur={async (e) => {
                const value = e.target.value;
                setPhoneError("");
                const phone_number = phone(value, {
                  country: registerFormData.country,
                }).phoneNumber;

                if (
                  phone(value, { country: registerFormData.country }).isValid
                ) {
                  const body = JSON.stringify({
                    phone_number,
                  });

                  try {
                    const response = await fetch(API_URL_PHONE_CHECAK, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body,
                    });
                    const responseJson = await response.json();
                    if (responseJson.result.status === "Success") {
                      setPhoneError(responseJson.result.message);
                      setEmailPhoneDuplicate((state: any) => ({
                        ...state,
                        phone: true,
                      }));
                    } else {
                      setEmailPhoneDuplicate((state: any) => ({
                        ...state,
                        phone: false,
                      }));
                    }
                  } catch (err) {
                    setPhoneError("Error in checking, type again");
                  }
                } else if (
                  registerFormData.phoneNumber === "" ||
                  !phone(registerFormData.phoneNumber, {
                    country: registerFormData.country,
                  }).isValid ||
                  emailPhoneDuplicate.phone
                ) {
                  setPhoneError("Enter valid phone number");
                }
              }}
            />
          </div>
          {phoneError !== "" && (
            <div className="text-[#FF725E] text-sm mx-2 text-left">
              {phoneError}
            </div>
          )}
        </div>
        <div
          className={`flex flex-col gap-2 items-start basis-1/2
          }`}
        >
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.contact-information.jobTitle",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <Input
            name="jobTitle"
            value={registerFormData.jobTitle}
            isError={registerFormErrorData.jobTitle}
            onChange={handleDataChange}
            className="bg-white h-12 text-base placeholder:text-base placeholder:text-gray-500 border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]"
            placeholder={`${t(
              "material-startup-page.register-form.contact-information.jobTitlePlaceholder",
            )}`}
            required
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <div className="flex flex-col gap-2 items-start flex-grow">
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.contact-information.businessEmail",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <Input
            name="businessEmail"
            value={registerFormData.businessEmail}
            onChange={handleDataChange}
            isError={registerFormErrorData.businessEmail}
            className="bg-white h-12 text-base placeholder:text-base placeholder:text-gray-500 border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]"
            placeholder={`${t(
              "material-startup-page.register-form.contact-information.businessEmailPlaceholder",
            )}`}
            onBlur={async (e) => {
              const value = e.target.value;
              setEmailError("");
              if (EmailValidator.validate(value)) {
                const body = JSON.stringify({
                  business_email: value,
                });

                try {
                  const response = await fetch(API_URL_EMAIL_CHECAK, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body,
                  });
                  const responseJson = await response.json();
                  if (responseJson.result.status === "Success") {
                    setEmailError(responseJson.result.message);
                    setEmailPhoneDuplicate((state: any) => ({
                      ...state,
                      email: true,
                    }));
                  } else {
                    setEmailPhoneDuplicate((state: any) => ({
                      ...state,
                      email: false,
                    }));
                  }
                } catch (err) {
                  setEmailError("Error in checking, type again");
                }
              } else if (
                registerFormData.businessEmail === "" ||
                !EmailValidator.validate(registerFormData.businessEmail) ||
                emailPhoneDuplicate.email
              ) {
                setEmailError("Enter valid email");
              }
            }}
          />
          {emailError !== "" && (
            <div className="text-[#FF725E] text-sm mx-2 text-left">
              {emailError}
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <div className="flex flex-col gap-2 items-start flex-grow">
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.contact-information.preferredLanguage",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <div className="w-full">
            <Select
              name="preferredLanguage"
              className={`h-12 text-base placeholder:text-base placeholder:text-gray-500 
              border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]
              ${registerFormData.preferredLanguage ? "" : "text-gray-400"}`}
              value={registerFormData.preferredLanguage}
              onChange={handleDataChange}
              placeholder={`${t(
                "material-startup-page.register-form.contact-information.preferredLanguagePlaceholder",
              )}`}
              required
              isError={registerFormErrorData.preferredLanguage}
            >
              {[...Array(LANGUAGES_COUNT)].map((_, i) => (
                <option
                  key={i}
                  value={`${t(
                    "material-startup-page.register-form.contact-information.languages." +
                      (i + 1).toString(),
                  )}`}
                >
                  {`${t(
                    "material-startup-page.register-form.contact-information.languages." +
                      (i + 1).toString(),
                  )}`}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}
