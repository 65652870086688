import React from 'react'
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { TranslationKey } from "types/translation";
import { PrimaryBtn } from "components/library";

type P = {
  title: TranslationKey;
  description: TranslationKey[];
  setOpenForm: Function;
};

export type MaterialStartupAdditionalBenefitsCardProps = P;

export const MaterialStartupAdditionalBenefitsCard = ({
  description,
  title,
  setOpenForm,
}: P) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col rounded-[38px] bg-[#28149C] shadow-md text-[#EDF1F4] p-6 sm:p-8 w-full">
      <div className="font-poppins leading-7 text-xl sm:text-3xl">{t(title)}</div>
      <div
        className={`flex flex-col md:flex-row gap-8 md:gap-12 py-4 md:justify-between md:items-end`}
      >
        <div className="flex flex-col gap-4">
          <ul className="list-disc pl-8">
            {description.map((line) => {
              return (
                <li key={line.toString()}>
                  <Trans i18nKey={`${line}`} />
                </li>
              );
            })}
          </ul>
        </div>
        {/* Button */}
        <div className="flex flex-col sm:flex-row gap-4 h-[100%] sm:w-[50%] lg:w-auto justify-end">
          <PrimaryBtn onClick={() => setOpenForm(true)} className='bg-[#28149C] text-[#EDF1F4] border-[#EDF1F4] hover:text-[#28149C]'>
            {t("common.cta.register-now")}
          </PrimaryBtn>
        </div>
      </div>
    </div>
  );
};
