import React from 'react'
import {
  Container,
  PrimaryBtn,
} from 'components/library'
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

export const MaterialStartupRegisterCard = ({ setOpenForm }: any) => {
  const { t } = useTranslation();

  return (
    <Container className="flex flex-col sm:flex-row items-center justify-center md:flex-row md:items-end gap-12 sm:gap-4 text-white py-16">
      <div className="flex flex-col justify-center items-center md:items-start px-4 lg:px-8 gap-6 w-[100%] md:w-[55%]">
        <div className="text-2xl sm:text-3xl md:text-2xl lg:text-4xl lg:leading-snug font-semibold uppercase max-w-sm text-center md:text-left font-poppins w-full contents">
          <Trans
            i18nKey={"material-startup-page.register.card.title"}
            components={{ br: <br /> }}
          />
        </div>

        <div className="text-sm sm:text-lg md:text-lg lg:text-lg text-center md:text-left font-poppins font-light text-[#bbb5dc]">
          {t("material-startup-page.register.card.subtitle")}
        </div>
      </div>

      <div className="flex flex-col items-center md:items-end px-4 lg:px-8 gap-6 w-[100%] md:w-[45%]">
        <PrimaryBtn
          tabIndex={-1}
          className="max-w-max bg-[#28149C] text-[#EDF1F4] border-[#EDF1F4] hover:text-[#28149C]"
          onClick={() => setOpenForm(true)}
        >
          {t("common.cta.register-now")}
        </PrimaryBtn>
        <div className="text-sm sm:text-lg md:text-lg lg:text-lg text-center md:text-right font-poppins font-light md:w-[80%] text-[#bbb5dc]">
          <Trans
            i18nKey={"material-startup-page.register.card.subtitle2"}
            components={{ br: <br /> }}
          />
        </div>
      </div>
    </Container>
  );
};
