import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Input, Select } from "components/library"
import DatePicker from "react-datepicker"
import { registerLocale } from "react-datepicker";
import ja from 'date-fns/locale/ja';
import en from 'date-fns/locale/en-GB';

const currLocale = process.env.GATSBY_LANGUAGE_SELECTED
if (currLocale === "ja") {
  registerLocale('ja', ja)
} else {
  registerLocale('en-GB', en)
}

import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css" // Workaround for date picker input field not going to full width

// const FUNDING = [
// "Seed Funding",
// "Series A Funding",
// "Series B funding",
// "No funding",
// ]

const FUNDING_COUNT = 4;

export default function RegisterFormFirstStep({
  registerFormData,
  setRegisterFormData,
  registerFormErrorData,
  setRegisterFormErrorData,
}: {
  registerFormData: any;
  setRegisterFormData: Function;
  registerFormErrorData: any;
  setRegisterFormErrorData: Function;
}) {
  const { t } = useTranslation();

  const handleDataChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setRegisterFormErrorData((prevState: any) => ({
      ...prevState,
      [name]: false,
    }));
    setRegisterFormData({
      ...registerFormData,
      [name]: value,
    });
  };

  return (
    <div className="flex flex-col gap-4 sm:gap-8 text-black">
      {/* title */}
      <div className="self-start font-medium text-black text-xl md:text-2xl my-2 sm:my-4">
        {t("material-startup-page.register-form.about-company.heading")}
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <div className="flex flex-col gap-2 items-start basis-1/2">
          <div className="text-black text-sm mx-2">
            {t("material-startup-page.register-form.about-company.companyName")}
            <span className="text-[#FF725E]">*</span>
          </div>
          <Input
            name="companyName"
            value={registerFormData.companyName}
            onChange={handleDataChange}
            className="bg-white h-12 text-base placeholder:text-base placeholder:text-gray-500 border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]"
            placeholder={`${t(
              "material-startup-page.register-form.about-company.companyNamePlaceholder",
            )}`}
            required
            isError={registerFormErrorData.companyName}
          />
        </div>
        <div className="flex flex-col gap-2 items-start basis-1/2">
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.about-company.dateOfEstablishment",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <div className="datePicker">
            <DatePicker
              className={`w-full rounded-md px-6 py-3 disabled:cursor-not-allowed disabled:opacity-50 bg-white h-12 text-base placeholder:text-base placeholder:text-gray-500 border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)] ${
                registerFormErrorData.dateOfEstablishment &&
                "outline-red-400 outline-1 outline text-red-400"
              }`}
              placeholderText={`${t(
                "material-startup-page.register-form.about-company.dateOfEstablishmentPlaceholder",
              )}`}
              showYearDropdown
              showMonthDropdown
              required
              locale="ja"
              name="dateOfEstablishment"
              selected={registerFormData.dateOfEstablishment}
              title={`${t("common.tooltips.requiredFieldTooltip")}`}
              onChange={(newDate: Date) => {
                setRegisterFormErrorData((prevState: any) => ({
                  ...prevState,
                  ["dateOfEstablishment"]: false,
                }));
                setRegisterFormData({
                  ...registerFormData,
                  ["dateOfEstablishment"]: new Date(newDate),
                });
              }}
            />
          </div>
          {/* <Input
            name="dateOfEstablishment"
            value={registerFormData.dateOfEstablishment}
            onChange={handleDataChange}
            type={"date"}
            className="bg-white h-12 text-base placeholder:text-base placeholder:text-gray-500 border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]"
            placeholder={`${t(
              "material-startup-page.register-form.about-company.dateOfEstablishmentPlaceholder",
            )}`}
            required
            isError={registerFormErrorData.dateOfEstablishment}
          /> */}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <div className="flex flex-col gap-2 items-start flex-grow">
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.about-company.headquarters",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <Input
            name="headquarters"
            value={registerFormData.headquarters}
            onChange={handleDataChange}
            className="bg-white h-12 text-base placeholder:text-base placeholder:text-gray-500 border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]"
            placeholder={`${t(
              "material-startup-page.register-form.about-company.headquartersPlaceholder",
            )}`}
            required
            isError={registerFormErrorData.headquarters}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <div className="flex flex-col gap-2 items-start basis-1/2">
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.about-company.currentLocationOfRndCenter",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <Input
            name="currentLocationOfRndCenter"
            value={registerFormData.currentLocationOfRndCenter}
            onChange={handleDataChange}
            className="bg-white h-12 text-base placeholder:text-base placeholder:text-gray-500 border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]"
            placeholder={`${t(
              "material-startup-page.register-form.about-company.currentLocationOfRndCenterPlaceholder",
            )}`}
            required
            isError={registerFormErrorData.currentLocationOfRndCenter}
          />
        </div>

        <div className="flex flex-col gap-2 items-start basis-1/2">
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.about-company.totalFundingRaised",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <div className="w-full">
            <Select
              name="totalFundingRaised"
              className={`h-12 text-base placeholder:text-base placeholder:text-gray-500 
              border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]
              ${registerFormData.totalFundingRaised ? "" : "text-gray-400"}`}
              value={registerFormData.totalFundingRaised}
              onChange={handleDataChange}
              placeholder={`${t(
                "material-startup-page.register-form.about-company.totalFundingRaisedPlaceholder",
              )}`}
              required
              isError={registerFormErrorData.totalFundingRaised}
            >
              {[...Array(FUNDING_COUNT)].map((_, i) => (
                <option
                  key={i}
                  value={`${t(
                    "material-startup-page.about-company.fundingTypes." +
                      (i + 1).toString(),
                  )}`}
                >
                  {`${t(
                    "material-startup-page.register-form.about-company.fundingTypes." +
                      (i + 1).toString(),
                  )}`}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}
