import { Container } from "components/library";
import React from "react";
import { Trans } from "react-i18next";
import IconFeatures1 from "images/material-startup/IconFeatures1.svg";
import IconFeatures2 from "images/material-startup/IconFeatures2.svg";
import IconFeatures3 from "images/material-startup/IconFeatures3.svg";
import IconFeatures4 from "images/material-startup/IconFeatures4.svg";

import { MaterialStartupFeaturesCard } from "./features-card";
import { TranslationKey } from "types/translation";

const features: {
  title: TranslationKey;
  description?: TranslationKey;
  illustration: string;
}[] = [
  {
    title: "material-startup-page.features.data.1.title",
    illustration: IconFeatures1,
  },
  {
    title: "material-startup-page.features.data.3.title",
    illustration: IconFeatures3,
  },
  {
    title: "material-startup-page.features.data.2.title",
    description: "material-startup-page.features.data.2.description",
    illustration: IconFeatures2,
  },

  {
    title: "material-startup-page.features.data.4.title",
    illustration: IconFeatures4,
  },
];

const MaterialStartupFeatures = () => {
  return (
    <section className="w-full z-10 relative">
      <Container className="flex flex-col items-center bg-[#FEFEFE] w-[85%] rounded-[16px] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]">
        <div className="flex flex-col gap-4 text-center mt-12 items-center">
          <p className="text-2xl sm:text-4xl font-poppins ">
            <Trans i18nKey={"material-startup-page.features.title"} />
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-12 justify-center flex-wrap md:flex-row">
          {features.map((feat) => (
            <MaterialStartupFeaturesCard
              title={feat.title}
              description={feat.description}
              illustration={feat.illustration}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default MaterialStartupFeatures;
