import React, { useEffect } from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import ThankYou from "../../../../../images/thank-you.svg"
import Error from "../../../../../images/error.svg"

export default function RegisterFormFourthStep({ submitResponse }: any) {
  const { t } = useTranslation()

  useEffect(() => {}, [])

  return (
    <div className="flex flex-col gap-4 sm:gap-8 text-black items-center py-2">
      <img
        alt={t(
          "material-startup-page.register-form.form-submit-acknowledgement.thank-you",
        )}
        src={
          submitResponse && submitResponse.result.status === "Success"
            ? ThankYou
            : Error
        }
        className="m-6 h-20 md:h-full motion-safe:animate-bounce"
      />
      <div
        className={`${
          submitResponse && submitResponse.result.status === "Success"
            ? "text-theme-blue"
            : "text-theme-orange"
        } text-md sm:text-xl lg:text-2xl font-medium`}
      >
        {submitResponse && submitResponse.result.status === "Success"
          ? submitResponse &&
            submitResponse.result.message === "You are already registered"
            ? t(
                "material-startup-page.register-form.form-submit-acknowledgement.message-title-already-registered",
              )
            : t(
                "material-startup-page.register-form.form-submit-acknowledgement.message-title",
              )
          : submitResponse &&
            submitResponse.result.message === "Provide a Valid Email"
          ? t(
              "material-startup-page.register-form.form-submit-acknowledgement.messsage-title-invalid-email",
            )
          : t(
              "material-startup-page.register-form.form-submit-acknowledgement.message-title-error",
            )}
      </div>

      <div className="text-xs sm:text-md lg:text-lg text-gray-600">
        <Trans
          i18nKey={
            submitResponse && submitResponse.result.status === "Success"
              ? "material-startup-page.register-form.form-submit-acknowledgement.message-description"
              : "material-startup-page.register-form.form-submit-acknowledgement.message-description-error"
          }
          components={{ br: <br /> }}
        />
      </div>
    </div>
  );
}
