import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Container, PrimaryBtn } from 'components/library'

export const MaterialStartupHero = ({ setOpenForm }: any) => {
  const { t } = useTranslation()
  const [price,setPrice] = useState(0)
  const [render,setRender] = useState(false)

  useLayoutEffect(()=>{
     const timeout = setTimeout(()=>{
      setRender(true)
    }, 1000)

    return ()=>{
      clearTimeout(timeout)
    }
  },[])

  useEffect(()=>{
     const timeout = setTimeout(()=>{
      if(render && price<25000) setPrice(state=>state+5)
    }, 0)

    return ()=>{
      clearTimeout(timeout)
    }
  },[price, render])

  return (
    <section 
    className="bg-material-startup bg-no-repeat bg-contain 2xl:bg-cover bg-[center_top_7rem] h-[600px] sm:h-[800px] lg:h-[1000px] w-full flex flex-col pt-20 justify-center">
      <Container className="flex justify-between gap-4 items-center pt-12 sm:pt-16 md:pt-14 lg:pt-10 pb-20">
        <div className="flex flex-col gap-6 flex-grow mx-8 lg:mx-0 my-8">
          <h1 className="max-w-max">
            <span className='text-[#FF730A] text-4xl sm:text-6xl md:text-8xl font-bold font-poppins transition-all'>${price}</span><br/>
            <Trans
              i18nKey={"material-startup-page.hero.heading"}
              components={{
                span1: <span className='text-[#FF730A] text-4xl sm:text-6xl md:text-8xl font-bold font-poppins transition-all'/>,
                span2: <span className='text-black text-3xl sm:text-4xl md:text-6xl font-bold font-poppins'/>,
                br: <br/>
              }}
            />
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl text-[#737373] font-normal">
            <Trans
              i18nKey={"material-startup-page.hero.description"}
              components={{
                span1: <span className='text-theme-orange font-medium'/>,
                span2: <span className='text-theme-orange'/>,
              }}
            />
          </p>
          <div className="flex flex-col sm:flex-row gap-4">
            <PrimaryBtn
              onClick={() => setOpenForm(true)}
              className='hover:text-[#28149C] hover:bg-[#ffffff] border-[#28149C] bg-[#28149C] text-[#fff]'
            >
              {t("common.cta.register-now")}
            </PrimaryBtn>
          </div>
        </div>
      </Container>
    </section>
  )
}
