import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import { TranslationKey } from 'types/translation'

type P = {
  title: TranslationKey;
  description?: TranslationKey;
  illustration: string;
};

export type MaterialStartupFeaturesCardProps = P;

export const MaterialStartupFeaturesCard = ({
  illustration,
  description,
  title,
}: P) => {
  return (
    <div
      className={`flex flex-row items-start w-[420px] max-w-[100%] gap-4 px-4 py-4 sm:py-8 rounded-md text-left`}
    >
      <div className="flex flex-none h-[48px] sm:h-[100%]">
        <img src={illustration} />
      </div>

      <div className="flex flex-col gap-4">
        <p className="text-[#353535] flex-none font-poppins text-base sm:text-2xl font-semibold">
          <Trans i18nKey={`${title}`} components={{ br: <br /> }} />
        </p>
        {description && (
          <p className="text-sm sm:text-lg flex-grow font-poppins font-normal text-[#737373]">
            <Trans i18nKey={`${description}`} />
          </p>
        )}
      </div>
    </div>
  );
};
