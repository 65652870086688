import React, { useState } from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import { TranslationKey } from 'types/translation'

type P = {
  title: TranslationKey
  description: TranslationKey
  illustration: string
  illustrationHover: string
}

export type MaterialStartupBenefitsCardProps = P

export const MaterialStartupBenefitsCard = ({
  illustration,
  description,
  title,
  illustrationHover
}: P) => {
  const [hover,setHover]=useState(false)
  return (
    <div
      className={`flex flex-col w-full gap-8 px-6 py-6 rounded-lg text-left ${hover?'bg-white':''} ease-in duration-200`}
      onMouseEnter={()=>setHover(true)}
      onMouseLeave={()=>setHover(false)}
    >
      <div className="flex items-center justify-start flex-none">
        <img src={hover?illustrationHover:illustration} />
      </div>

      <div className="flex flex-col gap-4">
        <p className={`text-sm sm:text-base font-bold ${hover?'text-[#232C35]':'text-[#EDF1F4]'} flex-none ease-in duration-200`}>
          <Trans i18nKey={`${title}`} components={{ br: <br /> }} />
        </p>
        <p className={`text-xs sm:text-sm flex-grow ${hover?'text-[#5E6B74]':'text-[#EDF1F4]'} font-poppins leading-5 font-medium ease-in duration-200`}>
          <Trans i18nKey={`${description}`} />
        </p>
      </div>
    </div>
  )
}
