import React, { ComponentPropsWithoutRef } from 'react'
import { mergeClasses } from 'utils'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { TranslationKey } from 'types/translation'
import { PrimaryBtn } from 'components/library'

type P = ComponentPropsWithoutRef<'div'> & {
  paragraphs: TranslationKey[]
  image: any
  reverse?: boolean
  register?: boolean
  setOpenForm: Function
}

export type SectionParagraphCardProps = P

export const SectionParagraphCard = ({
  paragraphs,
  image,
  reverse = false,
  register = false,
  className = '',
  setOpenForm,
  ...props
}: P) => {
  const { t } = useTranslation()
  return (
    <div
      className={mergeClasses({
        classes: [
          `flex flex-col items-center justify-center my-0 sm:my-8 ${
            reverse ? 'md:flex-row-reverse' : 'md:flex-row'
          }`,
          className
        ]
      })}
      {...props}
    >

      <div className={`w-full sm:w-[55%] flex flex-col gap-8 text-left px-0 sm:px-12 py-6 sm:py-0`}>
        <div className='text-[#1A2129] font-poppins font-bold text-2xl sm:text-4xl'>{<Trans i18nKey={`${paragraphs[0]}`} components={{ span: <span className='text-[#EF8129]' /> }} />}</div>
        {paragraphs.slice(1).map((para) => (
          <p className="text-[#737373] text-base leading-7 font-poppins font-normal">
            {<Trans i18nKey={`${para}`} components={{ b: <b /> }} />}
          </p>
        ))}
        {register && <div className="flex flex-col sm:flex-row gap-4">
            <PrimaryBtn
              onClick={() => setOpenForm(true)}
              className='hover:text-[#28149C] hover:bg-[#ffffff] border-[#28149C] bg-[#28149C] text-[#fff]'
            >
              {t("common.cta.register-now")}
            </PrimaryBtn>
          </div>}
      </div>

      <img
        alt={paragraphs[0]}
        className={`flex w-full sm:w-[45%] rounded-lg overflow-hidden`}
        src={image}
      />
    </div>
  )
}
