import React from 'react'
import { Container } from 'components/library'
import { TranslationKey } from 'types/translation'
import MaterialStartupDetails1 from 'images/material-startup/mst1.png'
import MaterialStartupDetails2 from 'images/material-startup/mst2.png'
import { SectionParagraphCard } from 'components/common/section-paragraph-card'

type P = {
  paragraphs: TranslationKey[]
  image: string
  register?: boolean
}[]

const materialStartupcardDetails: P = [
  {
    paragraphs: [
      'material-startup-page.details.paragraphs1.1',
      'material-startup-page.details.paragraphs1.2',
    ],
    image: MaterialStartupDetails2
  },
  {
    paragraphs: [
      'material-startup-page.details.paragraphs2.1',
      'material-startup-page.details.paragraphs2.2'
    ],
    image: MaterialStartupDetails1,
    register: true
  }
]
const MaterialStartupDetails = ({ setOpenForm }: any) => {
  return (
    <Container className="flex flex-col justify-between gap-6 lg:gap-12 items-center my-0 sm:my-12 px-12 lg:px-24">
      {materialStartupcardDetails.map((detail, index) => (
        <SectionParagraphCard
          paragraphs={detail.paragraphs}
          image={detail.image}
          reverse={index % 2 === 1}
          register={detail.register || false}
          setOpenForm={setOpenForm}
        />
      ))}
    </Container>
  )
}

export default MaterialStartupDetails
