import React, { Fragment, useCallback, useEffect, useState } from "react"
import { ArrowLeft, X } from "react-feather"
import { Dialog, Transition } from "@headlessui/react"
import RegisterFormFirstStep from "./register-form-first-step"
import RegisterFormThirdStep from "./register-form-third-step"
import RegisterFormSecondStep from "./register-form-second-step"
import RegisterFormFourthStep from "./register-form-fourth-step"
import { PrimaryBtn } from "components/library"
import StepProgressBar from "./step-progress-bar"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as EmailValidator from "email-validator";
import { phone } from "phone";

const API_URL = `${process.env.GATSBY_BACKEND_API_V1}/_material_startup_registration`;

const initialData = {
  companyName: "",
  dateOfEstablishment: "",
  headquarters: "",
  currentLocationOfRndCenter: "",
  totalFundingRaised: "",

  country: "+91",
  phoneNumber: "",
  jobTitle: "",
  businessEmail: "",
  preferredLanguage: "",

  expectedTeamSizeInTwoYears: "",
  problemsFacingInCurrentMaterialDevelopment: "",
  expectationsFromOurProduct: "",
};

const initialErrorData = {
  companyName: false,
  dateOfEstablishment: false,
  headquarters: false,
  currentLocationOfRndCenter: false,
  totalFundingRaised: false,

  country: false,
  phoneNumber: false,
  jobTitle: false,
  businessEmail: false,
  preferredLanguage: false,

  expectedTeamSizeInTwoYears: false,
  problemsFacingInCurrentMaterialDevelopment: false,
  expectationsFromOurProduct: false,
};

export const RegisterForm = ({ openForm, setOpenForm }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [registerFormData, setRegisterFormData] = useState(initialData);
  const [registerFormErrorData, setRegisterFormErrorData] =
    useState(initialErrorData);
  const [submitResponse, setSubmitResponse] = useState<any>();
  const { t } = useTranslation();
  const [emailPhoneDuplicate, setEmailPhoneDuplicate] = useState({
    email: false,
    phone: false,
  });

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "initial";
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(openForm);
  }, [openForm]);

  const close = useCallback(()=>{
    setIsOpen(false);
    setOpenForm(false);
    if (submitResponse.result.status === "Success") {
      setStep(1);
      setSubmitResponse(null);
    }
  },[submitResponse])

  // Checks if a given string only contains digits
  function isDigits(str: string) {
    return /^\d+$/.test(str);
  }

  async function onSubmit(step: number) {
    if (step === 1) {
      if (registerFormData.companyName === "") {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            companyName: true,
          };
        });
        return;
      }

      if (registerFormData.dateOfEstablishment === "") {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            dateOfEstablishment: true,
          };
        });
        return;
      }

      if (registerFormData.headquarters === "") {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            headquarters: true,
          };
        });
        return;
      }

      if (registerFormData.currentLocationOfRndCenter === "") {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            currentLocationOfRndCenter: true,
          };
        });
        return;
      }

      if (registerFormData.totalFundingRaised === "") {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            totalFundingRaised: true,
          };
        });
        return;
      }
      setStep((prevState) => prevState + 1);
      return;
    }

    if (step === 2) {
      if (registerFormData.country === "") {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            country: true,
          };
        });
        return;
      }

      if (
        registerFormData.phoneNumber === "" ||
        !phone(registerFormData.phoneNumber, {
          country: registerFormData.country,
        }).isValid ||
        emailPhoneDuplicate.phone
      ) {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            phoneNumber: true,
          };
        });
        return;
      }

      if (registerFormData.jobTitle === "") {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            jobTitle: true,
          };
        });
        return;
      }

      if (
        registerFormData.businessEmail === "" ||
        !EmailValidator.validate(registerFormData.businessEmail) ||
        emailPhoneDuplicate.email
      ) {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            businessEmail: true,
          };
        });
        return;
      }

      if (registerFormData.preferredLanguage === "") {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            preferredLanguage: true,
          };
        });
        return;
      }

      setStep((prevState) => prevState + 1);
      return;
    }

    if (step === 3) {
      if (
        registerFormData.expectedTeamSizeInTwoYears === "" ||
        Number(registerFormData.expectedTeamSizeInTwoYears) > 1000 ||
        Number(registerFormData.expectedTeamSizeInTwoYears) <= 0
      ) {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            expectedTeamSizeInTwoYears: true,
          };
        });
        return;
      }
      if (registerFormData.problemsFacingInCurrentMaterialDevelopment === "" || isDigits(registerFormData.problemsFacingInCurrentMaterialDevelopment)) {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            problemsFacingInCurrentMaterialDevelopment: true,
          };
        });
        return;
      }

      if (registerFormData.expectationsFromOurProduct === "" || isDigits(registerFormData.expectationsFromOurProduct)) {
        setRegisterFormErrorData((prevState) => {
          return {
            ...prevState,
            expectationsFromOurProduct: true,
          };
        });
        return;
      }

      const requestBody = {
        company_name: registerFormData.companyName,
        date_of_establishment: new Date(
          registerFormData.dateOfEstablishment,
        ).toLocaleDateString(),
        headquarters: registerFormData.headquarters,
        rnd_location: registerFormData.currentLocationOfRndCenter,
        phone_number: phone(registerFormData.phoneNumber, {
          country: registerFormData.country,
        }).phoneNumber,
        countery_code: "",
        job_title: registerFormData.jobTitle,
        business_email: registerFormData.businessEmail,
        language: registerFormData.preferredLanguage,
        team_size: registerFormData.expectedTeamSizeInTwoYears,
        problems: registerFormData.problemsFacingInCurrentMaterialDevelopment,
        expectations: registerFormData.expectationsFromOurProduct,
      };

      const body = JSON.stringify(requestBody);

      try {
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        });
        const responseJson = await response.json();
        setSubmitResponse((prevState:any)=>responseJson);
        setRegisterFormData((prevState)=>({...prevState,...initialData}));
        setStep((prevState) => prevState + 1);
      } catch (err) {
        console.log(err, { err });
        setSubmitResponse(err as any);
      }

      return;
    }
  }

  const stepContent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <RegisterFormFirstStep
            registerFormData={registerFormData}
            setRegisterFormData={setRegisterFormData}
            registerFormErrorData={registerFormErrorData}
            setRegisterFormErrorData={setRegisterFormErrorData}
          />
        );
      case 2:
        return (
          <RegisterFormSecondStep
            registerFormData={registerFormData}
            setRegisterFormData={setRegisterFormData}
            registerFormErrorData={registerFormErrorData}
            setRegisterFormErrorData={setRegisterFormErrorData}
            setEmailPhoneDuplicate={setEmailPhoneDuplicate}
            emailPhoneDuplicate={emailPhoneDuplicate}
          />
        );
      case 3:
        return (
          <RegisterFormThirdStep
            registerFormData={registerFormData}
            setRegisterFormData={setRegisterFormData}
            registerFormErrorData={registerFormErrorData}
            setRegisterFormErrorData={setRegisterFormErrorData}
          />
        );
      case 4:
        return <RegisterFormFourthStep submitResponse={submitResponse} />;

      default:
        return (
          <RegisterFormFirstStep
            registerFormData={registerFormData}
            setRegisterFormData={setRegisterFormData}
            registerFormErrorData={registerFormErrorData}
            setRegisterFormErrorData={setRegisterFormErrorData}
          />
        );
    }
  };

  if (!isOpen) return null;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={close}
      >
        <div className="flex relative items-center p-6 justify-center min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/50 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="bg-white rounded-md w-[95%] max-w-2xl p-5 sm:p-10 flex flex-col gap-5 sm:gap-8 text-center text-white relative">
            <div
              onClick={close}
              className="cursor-pointer text-gray-500 self-end"
            >
              <X />
            </div>
            <div className="h-16 flex items-center justify-center gap-4">
              <div className="font-semibold text-xl md:text-3xl text-black">
                {step !== 4
                  ? t("material-startup-page.register-form.form-step-title")
                  : t(
                      "material-startup-page.register-form.form-submit-acknowledgement.thank-you",
                    )}
              </div>
            </div>

            <StepProgressBar step={step} />

            <span className="flex-grow h-[2px] bg-[#D9DBE9] rounded-full opacity-30"></span>

            {stepContent(step)}

            <div className="flex flex-col md:flex-row justify-between pt-4 gap-4">
              {step > 1 && step < 4 ? (
                <div
                  onClick={() => setStep((prevState) => prevState - 1)}
                  className="px-6 py-3 flex gap-2 items-center justify-center text-theme-blue cursor-pointer w-full sm:w-auto"
                >
                  <ArrowLeft size={16} strokeWidth={3} />
                  {t("common.cta.previous-step")}
                </div>
              ) : (
                <div className={`${step === 4 ? "hidden" : ""}`} />
              )}
              {step < 4 ? (
                <PrimaryBtn
                  onClick={() => onSubmit(step)}
                  className="px-6 w-full sm:w-auto"
                >
                  {step === 3
                    ? t("common.cta.submit")
                    : t("common.cta.next-step")}
                </PrimaryBtn>
              ) : (
                <div className={`${step === 4 ? "hidden" : ""}`} />
              )}
            </div>
            {step === 4 ? (
              <div
                className={`flex ${
                  submitResponse && submitResponse.result.status !== "Success"
                    ? "justify-between"
                    : "justify-center"
                } flex-col md:flex-row`}
              >
                {submitResponse &&
                submitResponse.result.status !== "Success" ? (
                  <div
                    onClick={() => setStep((prevState) => prevState - 1)}
                    className="px-6 py-3 flex gap-2 items-center justify-center text-theme-blue cursor-pointer w-full sm:w-auto"
                  >
                    <ArrowLeft size={16} strokeWidth={3} />
                    {t("common.cta.previous-step")}
                  </div>
                ) : null}
                <PrimaryBtn onClick={close} className="w-full md:w-[25%]">
                  {t("common.cta.back-to-page")}
                </PrimaryBtn>
              </div>
            ) : null}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};