import { Container } from "components/library";
import React from "react";
import { Trans } from "react-i18next";
import FirstIcon from "images/material-startup/benefits1.svg";
import SecondIcon from "images/material-startup/benefits2.svg";
import ThirdIcon from "images/material-startup/benefits3.svg";
import FourthIcon from "images/material-startup/benefits4.svg";
import FirstIconHover from "images/material-startup/benefits1-hover.svg";
import SecondIconHover from "images/material-startup/benefits2-hover.svg";
import ThirdIconHover from "images/material-startup/benefits3-hover.svg";
import FourthIconHover from "images/material-startup/benefits4-hover.svg";
import { MaterialStartupBenefitsCard } from "./benefits-card";
import { MaterialStartupAdditionalBenefitsCard } from "./additional-benefits-card";
import { TranslationKey } from "types/translation";
import PolymerizeText from "images/material-startup/polymerize-text.svg";
import Molecule from "images/material-startup/molecule.svg";

const benefits: {
  title: TranslationKey;
  description: TranslationKey;
  illustration: string;
  illustrationHover: string
}[] = [
  {
    title: "material-startup-page.benefits.data.1.title",
    description: "material-startup-page.benefits.data.1.description",
    illustration: FirstIcon,
    illustrationHover: FirstIconHover
  },
  {
    title: "material-startup-page.benefits.data.2.title",
    description: "material-startup-page.benefits.data.2.description",
    illustration: SecondIcon,
    illustrationHover: SecondIconHover
  },
  {
    title: "material-startup-page.benefits.data.3.title",
    description: "material-startup-page.benefits.data.3.description",
    illustration: ThirdIcon,
    illustrationHover: ThirdIconHover
  },
  {
    title: "material-startup-page.benefits.data.4.title",
    description: "material-startup-page.benefits.data.4.description",
    illustration: FourthIcon,
    illustrationHover: FourthIconHover
  },
];

const additionalBenefits: {
  title: TranslationKey;
  description: TranslationKey[];
} = {
  title: "material-startup-page.additional-benefits.title",
  description: [
    "material-startup-page.additional-benefits.description.1",
    "material-startup-page.additional-benefits.description.2",
    "material-startup-page.additional-benefits.description.3",
    "material-startup-page.additional-benefits.description.4",
  ],
};

const MaterialStartupBenefits = ({ setOpenForm }: any) => {
  return (
    <section className="bg-[#1B088C] w-[80%] lg:w-[70%] relative z-3 rounded-[38px]">
      <div className="absolute left-8 top-4 w-[70%]">
        <img src={PolymerizeText} />
      </div>
      <div className="absolute right-3 top-2">
        <img src={Molecule} className="w-[50px] sm:w-full" />
      </div>
      <Container className="flex flex-col items-center p-6 sm:p-12 relative">
        <div className="flex flex-col gap-2 items-left mx-0 sm:mx-4 mt-4">
          <div className="flex flex-col gap-8 items-left mx-4">
            <span className="leading-normal text-2xl sm:text-4xl font-poppins text-[#EDF1F4] font-bold">
              <Trans i18nKey={"material-startup-page.benefits.heading"} />
            </span>
          </div>

          <div className="flex flex-col gap-4 text-left mx-4">
            <p className="font-poppins text-sm leading-5 sm:text-base sm:leading-7">
              <Trans
                i18nKey={"material-startup-page.benefits.description"}
                components={{
                  white: <span className="font-bold text-[#EDF1F4]" />,
                  blue: <span className="font-bold text-[#A7D9FF]" />,
                  br: <br />,
                  desc: <span className="font-normal text-[#EDF1F4]" />,
                  bold: <b className="text-[#FF730A]" />,
                }}
              />
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-12 my-8 justify-center flex-wrap md:flex-row">
          {benefits.map((feat) => (
            <MaterialStartupBenefitsCard
              title={feat.title}
              description={feat.description}
              illustration={feat.illustration}
              illustrationHover={feat.illustrationHover}
            />
          ))}
        </div>

        <div className="w-full">
          <MaterialStartupAdditionalBenefitsCard
            title={additionalBenefits.title}
            description={additionalBenefits.description}
            setOpenForm={setOpenForm}
          />
        </div>
      </Container>
    </section>
  );
};

export default MaterialStartupBenefits;
