import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Input, Select } from "components/library";

const TEAMS = ["0-20", "20-50", "50-100", "> 100"];

export default function RegisterFormThirdStep({
  registerFormData,
  setRegisterFormData,
  registerFormErrorData,
  setRegisterFormErrorData,
}: {
  registerFormData: any;
  setRegisterFormData: Function;
  registerFormErrorData: any;
  setRegisterFormErrorData: Function;
}) {
  const { t } = useTranslation();

  const handleDataChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setRegisterFormErrorData((prevState: any) => ({
      ...prevState,
      [name]: false,
    }));
    setRegisterFormData({
      ...registerFormData,
      [name]: value,
    });
  };

  return (
    <div className="flex flex-col gap-4 sm:gap-8 text-black">
      {/* title */}
      <div className="self-start font-medium text-black text-xl md:text-2xl my-2 sm:my-4">
        {t(
          "material-startup-page.register-form.expectations-and-challenges.heading",
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <div className="flex flex-col gap-2 items-start flex-grow">
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.expectations-and-challenges.questions.1",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <div className="w-full">
            <Select
              name="expectedTeamSizeInTwoYears"
              value={registerFormData.expectedTeamSizeInTwoYears}
              onChange={handleDataChange}
              isError={registerFormErrorData.expectedTeamSizeInTwoYears}
              className={`h-12 text-base placeholder:text-base placeholder:text-gray-500 
              border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]
              ${
                registerFormData.expectedTeamSizeInTwoYears
                  ? ""
                  : "text-gray-400"
              }`}
              placeholder={`${t(
                "material-startup-page.register-form.expectations-and-challenges.answer.1",
              )}`}
            >
              {TEAMS.map((team, idx) => {
                return (
                  <option key={idx} value={team}>
                    {team}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <div className="flex flex-col gap-2 items-start flex-grow">
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.expectations-and-challenges.questions.2",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <Input
            name="problemsFacingInCurrentMaterialDevelopment"
            type="text"
            value={registerFormData.problemsFacingInCurrentMaterialDevelopment}
            onChange={handleDataChange}
            isError={
              registerFormErrorData.problemsFacingInCurrentMaterialDevelopment
            }
            className="bg-white h-12 text-base placeholder:text-base placeholder:text-gray-500 border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]"
            placeholder={`${t(
              "material-startup-page.register-form.expectations-and-challenges.answer.2",
            )}`}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <div className="flex flex-col gap-2 items-start flex-grow">
          <div className="text-black text-sm mx-2">
            {t(
              "material-startup-page.register-form.expectations-and-challenges.questions.3",
            )}
            <span className="text-[#FF725E]">*</span>
          </div>
          <Input
            name="expectationsFromOurProduct"
            type="text"
            value={registerFormData.expectationsFromOurProduct}
            onChange={handleDataChange}
            isError={registerFormErrorData.expectationsFromOurProduct}
            className="bg-white h-12 text-base placeholder:text-base placeholder:text-gray-500 border-none shadow-[3px_1px_10px_-3px_rgba(0,0,0,0.2)]"
            placeholder={`${t(
              "material-startup-page.register-form.expectations-and-challenges.answer.3",
            )}`}
          />
        </div>
      </div>
    </div>
  );
}
