import React from "react"

export default function StepProgressBar({ step }: any) {
  const stepCircle = (stepToShow: number, currentStep: number) => {
    return (
      <div
        className={`flex items-center justify-center bg-[50%] transition-all ease-in-out duration-500 ${
          stepToShow <= currentStep ? "text-[#fff]" : "text-[#6F6C90]"
        } p-1 w-6 h-6 md:p-2 md:w-10 md:h-10 text-xs sm:text-md md:text-lg rounded-full ${
          stepToShow <= currentStep ? "bg-[#1753E2]" : "bg-[#EFF0F6]"
        }`}
      >
        {stepToShow}
      </div>
    )
  }

  const stepLine = (lineAfterStep: number, currentStep: number) => {
    return (
      <span className="flex-grow h-[5px] bg-[#EFF0F6] rounded-full relative">
        <span
          className={`flex-grow h-[5px] bg-[#1753E2] transition-all ease-in-out rounded-full absolute left-0 ${
            lineAfterStep === currentStep
              ? "w-[52%]"
              : lineAfterStep < currentStep
              ? "w-[100%]"
              : "w-[0%]"
          } 
            ${
              lineAfterStep === currentStep
                ? `duration-1000`
                : `duration-500`
            }
          `}
        ></span>
      </span>
    )
  }
  return (
    <div className="flex gap-2 md:gap-4 items-center mx-2 sm:mx-4 md:mx-10">
      {stepCircle(1, step)}
      {stepLine(1, step)}
      {stepCircle(2, step)}
      {stepLine(2, step)}
      {stepCircle(3, step)}
      {stepLine(3, step)}
      {stepCircle(4, step)}
    </div>
  )
}
